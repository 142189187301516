<template>
  <div>
    <v-navigation-drawer
      app
      v-if="isLoggedIn"
      v-model="showMenu"
      width="340"
      style="font-size: 14px"
    >
      <template #prepend>
        <div class="logo-content mb-2 pointer" @click="goHome">
          <div class="ml-2">
            <v-img width="35" src="@/assets/cekkmp.svg"></v-img>
          </div>
          <div class="ml-3">ЦЭККМП: УТИЛИТЫ</div>
        </div>
      </template>
      <MainMenu />
    </v-navigation-drawer>

    <v-app-bar app dense>
      <v-app-bar-nav-icon
        v-if="isLoggedIn"
        @click="showMenu = !showMenu"
      ></v-app-bar-nav-icon>

      <v-app-bar-title class="main-title text-truncate">
        <span v-html="title" />
      </v-app-bar-title>

      <v-spacer />
      <v-icon
        v-if="started && !online"
        color="warning"
        title="Отсутствует связь с сервером"
        >mdi-lan-disconnect</v-icon
      >
      <v-spacer />

      <div
        v-if="isLoggedIn"
        class="text-subtitle-2 mr-4 text-truncate"
        :title="userName"
      >
        <v-icon left small>mdi-account-circle-outline</v-icon>
        <span v-if="showRole" class="font-weight-bold mr-1"
          >{{ userRoleTitle }}:
        </span>
        <span>{{ userName | shortFIO }}</span>
      </div>

      <v-icon title="Выход" @click="logout" v-if="isLoggedIn"
        >mdi-exit-to-app</v-icon
      >
    </v-app-bar>

    <v-main>
      <div class="pa-8 pt-3">
        <BreadCrumbs
          v-if="isLoggedIn"
          show-on-main
          class="pa-1 pb-3 text-subtitle-2"
        />
        <router-view />
      </div>
    </v-main>

    <v-overlay v-if="loader" style="z-index: 999">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>

    <!-- ModalDialogs -->
    <dialogs-wrapper />
  </div>
</template>

<script>
import MainMenu from '@/components/menu/MainMenu'
import BreadCrumbs from '@/components/common/BreadCrumbs'
import { mapGetters, mapActions } from 'vuex'
import { getTitle } from '@/lib/strUtils'

export default {
  name: 'MainLayout',
  components: { MainMenu, BreadCrumbs },
  mounted() {
    this.CHECK_API().finally(() => {
      this.started = true
    })
  },
  data: () => ({
    showMenu: true,
    started: false,
    width: 0,
  }),
  computed: {
    ...mapGetters([
      'online',
      'isLoggedIn',
      'userName',
      'userRole',
      'isAdmin',
      'userRoleTitle',
      'hasLoading',
      'getTitle',
    ]),
    loader() {
      return this.hasLoading
    },
    showRole() {
      return false
    },
    title() {
      const { captionHtml, caption, title } = this.$route.meta
      return (
        getTitle(captionHtml) ||
        this.getTitle ||
        getTitle(caption) ||
        getTitle(title) ||
        document.title
      )
    },
  },
  methods: {
    ...mapActions(['logout', 'CHECK_API']),
    async goHome() {
      if (this.$router.currentRoute.fullPath !== '/')
        this.$router.push('/').catch()
    },
  },
}
</script>

<style scope>
.logo-content {
  font-family: 'ErbaumBook', sans-serif !important;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 1.5em;
  height: 48px;
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 45%,
    rgb(245, 245, 245) 66%
  );
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
}
.main-title {
  font-family: 'ErbaumBook', sans-serif !important;
  font-weight: 600;
  font-size: 1.1em;
}
.tip {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
  transition: opacity 0.2s ease;
  opacity: 0.6;
}
.tip:hover {
  opacity: 1;
}
/* Класс (цвет) подсветки в поиске */
.highlight {
  background-color: lightskyblue;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

<style>
.red-line {
  text-indent: 1.5em;
}

.cursor-help {
  cursor: help;
}

.pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: calc(6px);
  height: calc(6px);
}

::-webkit-scrollbar-track {
  cursor: pointer !important;
  background: white;
}

::-webkit-scrollbar-thumb {
  cursor: pointer !important;
  background: #c4c6c4;
  border-radius: calc(5px);
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
