/* eslint-disable no-dupe-class-members */
import axios from 'axios'

export const GET = 'GET'
export const POST = 'POST'
export const DELETE = 'DELETE'
export const PATCH = 'PATCH'
export const PUT = 'PUT'

export class EApiException extends Error {
  constructor(code, message) {
    super((code ? `${code} - ` : '') + message)
    this.name = 'EApiException'
    this.code = code
  }
  toString() {
    return `${this.name}: ${this.message}`
  }
}

export class EApiDownloadFileException extends EApiException {
  constructor(fileName, error) {
    const msg = `Ошибка получения файла "${fileName}": ${error}`
    super(100, msg)
    this.name = 'EApiDownloadFileException'
    this.message = msg
    this.error = error
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiCanceledException extends EApiException {
  constructor() {
    const msg = 'Отменено пользователем'
    super(msg)
    this.name = 'EApiCanceledException'
    this.message = msg
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiInternalException extends EApiException {
  constructor(
    code = 500,
    message = 'Внутренняя ошибка сервера или сервер не доступен!'
  ) {
    super(code, message)
    this.name = 'EApiInternalException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiConflictException extends EApiException {
  constructor(code = 409, message = 'Конфликт при изменения ресурса') {
    super(code, message)
    this.name = 'EApiConflictException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiBadRequestException extends EApiException {
  constructor(message) {
    super(400, message)
    this.name = 'EApiBadRequestException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiForbiddenException extends EApiException {
  constructor(code = 403, message = 'Нет прав для доступа к ресурсу') {
    super(code, message)
    this.name = 'EApiForbiddenException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiUnauthorizedException extends EApiException {
  constructor(code = 401, message = 'Пользователь не авторизован!') {
    super(code, message)
    this.name = 'EApiUnauthorizedException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

/** Content-Disposition извлекаем имя файла и кодировку */
function extractFileName(contentDisposition, defFilename = 'file') {
  // attachment; filename="file name; with (;).jpg"
  // attachment; filename*=utf-8''%D1%82%D0%B5%D1%81%D1%82;2023-09-27.xlsx; filename=filename.jpg
  // attachment; filename*=utf-8''"%D1%82%D0%B5%D1%81%D1%82; 2023-09-27.xlsx"
  // attachment; filename=report-2023-09-27.xlsx; test
  // attachment; filename="filena me.jpg"; test
  // attachment; filename=filename.jpg; filename=filename.jpg
  // attachment; filename=file;name.jpg
  const filenameRegex =
    /(?<=;|\s|^)filename(?:=|\*=(?<cp>.*)'')(?:"(?<fn1>[^:*?|<>\\/"]*)"|(?<fn2>[^:*?|<>\\/"\s]*))(?=;|$)/gim

  const matches = filenameRegex.exec(contentDisposition)

  const fn = matches?.groups['fn2'] || matches?.groups['fn1']
  console.log('contentDisposition', matches, fn)
  if (matches?.groups['cp'] === 'utf-8')
    try {
      return decodeURI(fn) || defFilename
    } catch (error) {
      console.error("can't decode filename", fn)
      return defFilename
    }
  return fn || defFilename
}

class Api {
  #axios = axios.create({
    baseURL: '/api/',
  })

  // скачивает файл с названием fileName по ссылке url (типа type файла из ответа берется)
  async getFile(url, defFileName, signal) {
    try {
      const responseType = 'blob'
      const { data, headers } = await this.#axios.get(url, {
        responseType,
        signal,
      })
      const fileName = extractFileName(
        headers['content-disposition'],
        defFileName
      )
      const type = headers['content-type'] || 'application/octet-stream'
      const blob = new Blob([data], { type })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      URL.revokeObjectURL(link.href)
    } catch (e) {
      if (e instanceof axios.Cancel) {
        throw new EApiCanceledException()
      } else {
        throw new EApiDownloadFileException(defFileName, e)
      }
    }
  }

  createAbortController = () => new AbortController()

  #doAxios(uri = '', data, method, config) {
    const axios = this.#axios
    if (method === GET || !method) return axios.get(uri, config)
    if (method === POST) return axios.post(uri, data, config)
    if (method === PATCH) return axios.patch(uri, data, config)
    // special way to send axios delete request with body
    if (method === DELETE) return axios.delete(uri, { ...config, data })
    if (method === PUT) return axios.put(uri, data, config)
  }

  #apiOnUnauthorized = null
  #apiOnUnauthorizedLock = 0
  #apiOnUnauthorizedLockVersion = 1

  unauthorizedEvent(event) {
    if (typeof event === 'function' || !event) this.#apiOnUnauthorized = event
    else throw new Error('Api unauthorizedEvent error, event is not a function')
  }

  /** Устанавливаем токен доступа для подключения */
  setAccessToken(tokken) {
    if (tokken) {
      this.#axios.defaults.headers.common['Authorization'] = `Bearer ${String(
        tokken
      ).trim()}`
    } else {
      delete this.#axios.defaults.headers.common['Authorization']
    }
  }

  /** Устанавливаем версию ПО - клиента для запроса к серверу ( опционально ) */
  setApiVersion(version) {
    const { common } = this.#axios.defaults.headers
    if (version) {
      common['X-Version'] = String(version ?? '').trim()
    } else {
      delete common['X-Version']
    }
  }

  cancelUnauthorizedEventWaitQuery() {
    this.#apiOnUnauthorizedLockVersion++
  }

  async #doUnauthorizedEvent(url) {
    // запомнили версию на входе
    const enterVersion = this.#apiOnUnauthorizedLockVersion

    while (this.#apiOnUnauthorizedLock) {
      // если есть блокировка то просто висим 100 мс и ждём
      await new Promise(r => setTimeout(r, 100))
      if (this.#apiOnUnauthorizedLockVersion > enterVersion) {
        console.log('I`m die...', url)
        throw new EApiException(0, 'Canceled') // выходим громко
      }
      if (!this.#apiOnUnauthorizedLock) return // выходим молча на 2 попытку
    }
    // если заскочили в проверку ... и есть событие
    if (typeof this.#apiOnUnauthorized === 'function') {
      try {
        this.#apiOnUnauthorizedLock++
        await this.#apiOnUnauthorized()
      } catch {
        throw new EApiUnauthorizedException()
      } finally {
        this.#apiOnUnauthorizedLock--
      }
    } else {
      // Что то сделать если нет авторизации
      console.info('Empty unauthorized event')
      throw new EApiUnauthorizedException()
    }
  }
  get(url, config = {}) {
    const { direct, ...configObj } = config
    return this.call(url, undefined, GET, direct, configObj)
  }
  post(url, data, config = {}) {
    const { direct, ...configObj } = config
    return this.call(url, data, POST, direct, configObj)
  }
  patch(url, data, config = {}) {
    const { direct, ...configObj } = config
    return this.call(url, data, PATCH, direct, configObj)
  }
  put(url, data, config = {}) {
    const { direct, ...configObj } = config
    return this.call(url, data, PUT, direct, configObj)
  }
  delete(url, data, config = {}) {
    const { direct, ...configObj } = config
    return this.call(url, data, DELETE, direct, configObj)
  }

  async call(url, data, method, direct = false, config) {
    // функция для повтора
    const retryAuthAndRetryCallFunc = async () => {
      if (direct) {
        throw new EApiUnauthorizedException()
      } else {
        await this.#doUnauthorizedEvent(url)
        // повторим запрос ???
        return this.call(url, data, method, true)
      }
    }

    try {
      const { data: resp } = await this.#doAxios(url, data, method, config)

      if (resp.answer === 'ok') {
        return resp.data
      } else {
        // обработка внутренних ошибок - статусов
        if (resp.statusCode === 401) {
          return retryAuthAndRetryCallFunc()
        } else if (resp.statusCode === 409) {
          throw new EApiConflictException(resp.statusCode, resp.message)
        } else throw new EApiException(resp.statusCode, resp.message)
      }
    } catch (err) {
      //Если отменено пользователем
      if (err instanceof axios.Cancel) {
        throw new EApiCanceledException()
      }
      // обработка транспортных ошибок HTTP
      if (err.response?.status === 400) {
        throw new EApiBadRequestException(err.response?.data?.message)
      } else if (err.response?.status === 401) {
        return retryAuthAndRetryCallFunc()
      } else if (err.response?.status === 403) {
        throw new EApiForbiddenException()
      } else if (err.response?.status === 409) {
        throw new EApiConflictException()
      } else if ([500, 503].includes(err.response?.status)) {
        throw new EApiInternalException(err.response.status)
      } else if (err.response?.status) {
        throw new EApiException(err.response?.status, err)
      } else throw err
    }
  }

  async checkServer() {
    try {
      const { data } = await this.#doAxios()
      if (data.answer === 'ok') {
        console.info(data.data)
        return true
      } else {
        return false
      }
    } catch {
      return false
    }
  }
}

/* default class Api */
export const api = new Api()

export default api
