<template>
  <v-list-item
    v-if="visible"
    v-bind="$attrs"
    :exact="exact"
    :to="to"
    :active-class="activeClass"
  >
    <v-list-item-icon v-if="!hideIcon" class="mr-2">
      <v-icon :small="small">{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title :class="titleClass">
        {{ title }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="subtitle">
        {{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { getTitle } from '@/lib/strUtils'
import { getObject } from '@/lib/objects'

export default {
  name: 'MenuItem',
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    itemInPath() {
      const { matched } = this.$route
      return !!matched.find(({ name }) => name === this.item?.name)
    },
    visible() {
      return !this.item?.meta.menuByRoute || this.itemInPath
    },
    icon() {
      return this.item?.meta.icon || 'mdi-blank'
    },
    title() {
      return getTitle(this.item?.meta.title)
    },
    subtitle() {
      return getTitle(this.item?.meta.subtitle)
    },
    exact() {
      return this.item?.meta.exact ?? true
    },
    to() {
      const { params } = this.$route
      const { name, props } = this.item || {}
      const addParams = this.visible && props ? { params } : {}
      const defParams = getObject(this.item?.meta.params)
      return { name, ...addParams, ...defParams }
    },
    style() {
      return this.item?.meta.style || {}
    },
    activeClass() {
      return this.style.activeClass || 'green lighten-3'
    },
    titleClass() {
      return this.style.titleClass || 'text-body-1'
    },
  },
}
</script>
