import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  ROUTE_LOGIN,
  ROUTE_MAIN,
  ROUTE_DEMO,
  ROUTE_403,
  ROUTE_404,
  menuStyleLevel1,
} from './const'
import store from '@/store'
import { getTitle } from '@/lib/strUtils'
import { attributesCheck } from '@/lib/userAttrs'
import { rolesCheck } from '@/lib/userRoles'

import refsRoutes from '../modules/refs/route'
import orphanRoutes from '../modules/orphan/route'
import demoRoutes from '../modules/demo/route'
import standartsRoutes from '../modules/standarts/route'
import requirementRoutes from '../modules/requirement/route'

// admin routes
import usersRoutes from '../modules/admin/users/route'

VueRouter.prototype.absUrl = function (url, newTab = true) {
  const link = document.createElement('a')
  link.href = url
  link.target = newTab ? '_blank' : ''
  if (newTab) link.rel = 'noopener noreferrer'
  link.click()
}

Vue.use(VueRouter)

export class ERoutersException extends Error {
  constructor(message) {
    super(`[routes] ${message}`)
    this.name = 'ERoutersException'
  }
}

const routes = [
  {
    path: '/',
    name: ROUTE_MAIN,
    component: () =>
      import(/* webpackChunkName: "main" */ '@/pages/MainView.vue'),
    meta: {
      requireAuth: true,
      title: 'Главная',
      captionHtml: () =>
        `<span class="text--disabled">Сегодня ${new Date().toLocaleDateString()}</span>`,
      icon: 'mdi-kabaddi',
    },
  },
  {
    path: '/demo',
    name: ROUTE_DEMO,
    component: () =>
      import(/* webpackChunkName: "demo" */ '@/pages/DemoView.vue'),
    meta: {
      menuByRoute: true,
      requireAuth: true,
      title: 'Демонстрация',
      icon: 'mdi-checkbox-blank-badge-outline',
      style: menuStyleLevel1,
    },
    children: [...demoRoutes],
    redirect: {
      name: ROUTE_MAIN,
    },
  },
  // Каталог Стандартов
  ...standartsRoutes,
  // Расчёт потребностей по утв стандартам
  ...requirementRoutes,
  // Справочники
  ...refsRoutes,
  // Администрирование - Юзеры
  ...usersRoutes,
  // прочие роуты
  {
    path: '/login',
    name: ROUTE_LOGIN,
    component: () =>
      import(/* webpackChunkName: "user" */ '@/pages/login/LoginPage.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () =>
      import(/* webpackChunkName: "user" */ '@/pages/ResetPasswordPage.vue'),
    meta: {
      title: 'Сброс пароля',
    },
  },
  // 403: access-denied
  {
    path: '/403-access-denied',
    name: ROUTE_403,

    component: () =>
      import(/* webpackChunkName: "p403" */ '@/pages/AccessDeniedView'),
    meta: {
      title: '403 - Отказано в доступе',
      breadSkip: true,
    },
  },
  // 404: page-not-found
  {
    path: '/404-page-not-found',
    name: ROUTE_404,
    alias: '*',
    meta: {
      requireAuth: true,
      title: '404 - Страница не найдена',
      breadSkip: true,
    },
    component: () =>
      import(/* webpackChunkName: "p404" */ '@/pages/PageNotFoundView'),
  },
]

/** Регистрируем роуты
 *  @param {route[]} routeItems перечень добавляемых роутов
 *  @param {string} after добавить после имени (путь через "/")
 *  @param {string} before добавить до имени (путь через "/")
 */
export const registerRoute = (
  routeItems = [],
  { after, before } = { before: ROUTE_DEMO }
) => {
  const findFunc = path => {
    let item = null
    let items = routes
    let parents = routes

    for (const route of path.split('/')) {
      if (!route) {
        item = null
        items = parents

        break
      }
      // ищем кандидата
      item = parents?.find(({ name }) => name === route)

      if (!item)
        throw new ERoutersException(`Не найден элемент пути ${route} в ${path}`)
      // оставить родителя только
      items = parents
      // переключаемся для поиска
      parents = item.children
    }
    return { name, items, item }
  }
  const addFunc = (items = [], item, routes, after = true) => {
    if (item) {
      const i = items.indexOf(item)
      const tails = items.splice(i + (after ? 1 : 0))
      items.push(...routes, ...tails)
    } else {
      items.push(...routes)
    }
  }

  if (after) {
    const { items, item } = findFunc(after)
    addFunc(items, item, routeItems)
  } else if (before) {
    const { items, item } = findFunc(before)
    addFunc(items, item, routeItems, false)
  } else {
    addFunc(routes, null, routeItems)
  }
}

// Register new route
//registerRoute(refsRoutes, { before: ROUTE_USERS })
registerRoute(orphanRoutes)
//registerRoute(demoRoutes, { after: `${ROUTE_DEMO}/` })

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

function getDocumentTitle(to) {
  const { title, caption } = to.meta
  return `ЦЭККМП : ${getTitle(caption) || getTitle(title) || 'Платформа'}`
}

/** проверка доступа - 403 */
const accessCheck = to => {
  if (store.getters.isAdmin) return true

  const { requireAuth, role, attributes } = to?.meta || {}

  if (requireAuth) {
    // проверка ролей
    if (!rolesCheck(store.getters.userRole, role)) {
      return false
    }
    // проверка по аттрибутам
    if (!attributesCheck(store.getters.userAttributes, attributes)) {
      return false
    }
  }
  // иначе можно
  return true
}

router.beforeEach((to, from, next) => {
  if (store.getters.hasLoginProceed) {
    //api.cancelUnauthorizedEventWaitQuery() // Можно попытаттся отчистить все кто ждёт авториацию
    return next(false)
  }

  // на логин не идём если уже залогины
  const isLoggedIn = store.getters.isLoggedIn

  if (to.name === ROUTE_LOGIN && isLoggedIn) {
    router.push({ path: '/' }).catch(() => {})
  }

  // Отбой если есть не сохранённые данные
  if (isLoggedIn && store.getters.hasUnsavedChanges) {
    Vue.$toast.warning(
      'Не могу уйти со страницы. Есть несохраненные изменения',
      { timeout: 5000 }
    )
    return next(false)
  }

  // Отбой если ешё в процессе загрузки
  if (isLoggedIn && store.getters.hasLoading) {
    Vue.$toast.warning(
      'Не могу уйти со страницы. Не завершена загрузка данных',
      { timeout: 5000 }
    )
    return next(false)
  }

  if (
    to.matched.some(record => record.meta.requireAuth) &&
    to.name !== ROUTE_LOGIN
  ) {
    if (!isLoggedIn) {
      const redirect = to.fullPath ?? undefined
      router.push({ name: ROUTE_LOGIN, query: { redirect } }).catch(() => {})
      return
    }
  }

  // Отбой если роут закрыт для этой роли
  if (!accessCheck(to)) {
    // на главную если шли с логина и не пускают 403
    if (from.name === ROUTE_LOGIN)
      router.push({ name: ROUTE_MAIN }).catch(() => {})
    else {
      router.replace({ name: ROUTE_403 }).catch(() => {})
      Vue.$toast.error('403 - Доступ закрыт ')
    }
    return
  }

  next()
})

router.afterEach(to => {
  document.title = getDocumentTitle(to)
})

export default router
