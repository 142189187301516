<template>
  <v-list v-bind="$attrs" :dense="dense" nav class="pa-0">
    <template v-for="({ title, items }, index) in groups">
      <div :key="index">
        <template v-if="hasGroupTitle">
          <v-subheader v-if="title" class="secondary--text">
            {{ title }}
          </v-subheader>
          <v-subheader v-else-if="index !== 0"><v-divider /></v-subheader>
        </template>
        <template v-for="(item, itemIndex) in items">
          <MenuListItem :key="itemIndex" :item="item" :filter="filter" />
        </template>
      </div>
    </template>
  </v-list>
</template>

<script>
import MenuListItem from './MenuListItem'

export default {
  name: 'MenuList',
  components: { MenuListItem },
  inheritAttrs: false,
  props: {
    routers: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    groupProp: {
      type: String,
      default: 'group',
    },
    filter: {
      type: Function,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    items() {
      return this.filter
        ? this.routers.filter(route => this.filter(route))
        : this.routers
    },
    groups() {
      const groupSet = new Map()
      this.items.forEach(route => {
        const title = String(route.meta?.[this.groupProp] ?? '').trim()
        const group = title || 'def'
        if (!groupSet.has(group)) {
          const el = { title, items: [] }
          groupSet.set(group, el)
        }
        groupSet.get(group).items.push(route)
      })
      return [...groupSet.values()]
    },
    hasGroupTitle() {
      return this.groups.find(({ title }) => !!title)
    },
  },
}
</script>
