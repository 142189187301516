<template>
  <div v-if="single">
    <MenuItem v-bind="$attrs" :item="item" />
    <!-- элементы если родитель в по текущем пути  -->
    <template v-if="hasSubItems">
      <MenuListItem
        v-for="(item, index) in subItems"
        :key="index"
        :item="item"
        small
        class="pl-5"
      />
    </template>
  </div>
  <v-list-group
    v-else-if="hasSubItems"
    v-bind="$attrs"
    color="text"
    :value="isGroupOpen"
  >
    <template v-slot:activator>
      <v-list-item-icon class="mr-2">
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-title :class="titleClass">
        {{ title }}
      </v-list-item-title>
    </template>
    <!-- группа  -->
    <MenuListItem
      v-for="(item, index) in subItems"
      :key="index"
      :item="item"
      small
      class="pl-5"
    />
  </v-list-group>
</template>

<script>
import MenuItem from './MenuItem'
import { getTitle } from '@/lib/strUtils'

export default {
  name: 'MenuListItem',
  components: { MenuItem },
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
    },
    filter: {
      type: Function,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    single() {
      return !this.group || this.item?.meta.singleMenu
    },
    group() {
      return this.item?.children?.length > 0
    },
    subItems() {
      return (
        (this.filter
          ? this.item?.children?.filter(route => this.filter(route))
          : this.item?.children) ?? []
      )
    },
    hasSubItems() {
      return this.subItems.length > 0
    },
    itemInPath() {
      const { matched } = this.$route
      return !!matched.find(({ name }) => name === this.item?.name)
    },
    isGroupOpen() {
      return this.itemInPath || this.$route.path.startsWith(this.item.path)
        ? 1
        : 0
    },
    icon() {
      return this.item?.meta.icon || 'mdi-blank'
    },
    title() {
      return getTitle(this.item?.meta.title)
    },
    titleClass() {
      return this.item?.meta.style?.titleClass || 'text-body-1'
    },
  },
}
</script>
