import { menuStyleLevel1 } from '@/router/const'

/** Может просматривать фильтры */
export const DemoVzn14 = 'demo.vzn14'

const routes = [
  {
    path: 'vzn14',
    name: 'DemoVzn14',
    component: () =>
      import(/* webpackChunkName: "demo" */ '../views/DemoVzn14View.vue'),
    meta: {
      menuByRoute: true,
      exact: true,
      requireAuth: true,
      title: 'Модель пациентов 14 ВЗН',
      caption: 'Формирования моделей пациентов 14 ВЗН',
      attributes: [DemoVzn14],
      icon: 'mdi-alpha-v-circle-outline',
      style: menuStyleLevel1,
    },
  },
]

export default routes
