import store from '@/store'
import { orphanModule } from '../store/const'
import { menuStyleLevel1, menuStyleLevel2 } from '@/router/const'

/** Может просматривать фильтры */
export const FiltersCanRead = 'filters.canRead'

const routes = [
  {
    path: '/filters',
    name: 'Filters',
    component: () =>
      import(/* webpackChunkName: "filters" */ '../views/OrphanView.vue'),
    meta: {
      menu: true,
      exact: false,
      singleMenu: true,
      requireAuth: true,
      title: 'Списки фильтров',
      attributes: [FiltersCanRead],
      icon: 'mdi-pill',
      style: menuStyleLevel1,
    },
    children: [
      {
        path: '',
        name: 'FiltersPage',
        component: () =>
          import(/* webpackChunkName: "filters" */ '../views/FiltersView.vue'),
        meta: {
          requireAuth: true,
          attributes: [FiltersCanRead],
          title: 'Список фильтров',
          breadSkip: true,
          icon: 'mdi-pill',
        },
      },
      {
        path: ':id',
        name: 'FilterData',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "filters" */ '../views/FilterDataView.vue'
          ),
        meta: {
          // menuByRoute: true, // если нужно показать меню когда мы в роуте
          requireAuth: true,
          attributes: [FiltersCanRead],
          title: () =>
            store.getters[`${orphanModule}/storedFilterName`] || '...',
          caption: 'Настройка фильтра',
          icon: 'mdi-filter',
          style: menuStyleLevel2,
        },
      },
    ],
    redirect: {
      name: 'FiltersPage',
    },
  },
]

export default routes
