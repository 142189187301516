export const clinRecFields = [
  {
    title: 'Дата:',
    value: 'change_date',
  },
  {
    title: '',
    value: 'cr_id',
    subfield: {
      title: 'Возрастная категория:',
      func: x => {
        if (!x) return ''
        let res = ''
        switch (x.toUpperCase().charAt(0)) {
          case 'A':
            res = 'Взрослые'
            break
          case 'C':
            res = 'Дети'
            break
          case 'N':
            res = 'Взрослые и дети'
            break
          default:
            res = 'Не указан'
            break
        }
        return res
      },
    },
  },
  {
    title: 'ID КР в рубрикаторе:',
    value: 'cr_ext_id',
  },
  {
    title: 'Коды МКБ:',
    value: 'mkb',
    subfield: {
      title: 'Коды МКБ:',
    },
  },
  {
    title: 'Ассоциация:',
    value: 'association',
  },
]

export const standartFields = [
  {
    title: 'Возрастная категория: ',
    value: 'age_cat',
    subfield: {
      title: 'Возрастная категория:',
    },
  },
  {
    title: 'Номер приказа:',
    value: 'order_num',
  },
  {
    title: 'Дата приказа: ',
    value: 'order_date',
  },
  {
    title: 'Код стандарта:',
    value: 'std_id',
    subfield: {
      title: 'Код стандарта:',
    },
  },
  {
    title: '',
    value: 'diag',
    subfield: {
      title: 'Коды МКБ:',
    },
  },
]

export const SCN = {
  GROUP: 'StandartsGroup',
  CLIN_REC: 'StandartsClinRec',
  STANDART: 'StandartsStandart',
  MODULE: 'StandartsModule',
}
