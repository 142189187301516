<template>
  <v-app>
    <main-layout />
  </v-app>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'

export default {
  name: 'App',
  components: { MainLayout },
}
</script>

<style lang="scss">
@font-face {
  font-family: 'ErbaumBook';
  src: url('./assets/fonts/Erbaum-Book.eot');
  src: local('Erbaum Book'), local('Erbaum-Book'),
    url('/assets/fonts/Erbaum-Book.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Erbaum-Book.woff2') format('woff2'),
    url('./assets/fonts/Erbaum-Book.woff') format('woff'),
    url('./assets/fonts/Erbaum-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.custom-fade-transition {
  &-enter-active {
    transition: opacity 0.2s;
  }
  &-leave-active,
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
