import { menuStyleLevel1, ROUTE_REQUIREMENT } from '@/router/const'

/** Может производить расчёт потребностей */
export const RequirementCanReqCalcStandarts = 'std.canReqCalc'

const routes = [
  {
    path: '/requirement',
    name: ROUTE_REQUIREMENT,
    component: () =>
      import(
        /* webpackChunkName: "кequirement" */ '../views/RequirementView.vue'
      ),
    meta: {
      menu: true,
      exact: false,
      singleMenu: true,
      requireAuth: true,
      title: 'Расчёт потребностей ЛП',
      attributes: [RequirementCanReqCalcStandarts],
      icon: 'mdi-calculator',
      style: menuStyleLevel1,
    },
  },
]

export default routes
