/** Проверка роли пользователя {userRole} на соответствие {roles} */
export const rolesCheck = (userRole, roles) => {
  if (!userRole && roles) return false
  if (!roles) return true
  if (typeof roles === 'string') {
    roles = [roles]
  }

  if (Array.isArray(roles) && !roles.includes(userRole)) return false

  return true
}
