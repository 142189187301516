import api from '../api'

export const login = {
  setAccessToken: token => {
    return api.setAccessToken(token)
  },

  setApiVersion: version => {
    return api.setApiVersion(version)
  },

  unauthorizedEvent: f => {
    return api.unauthorizedEvent(f)
  },

  loginCheckAuth: () => {
    return api.get('auth/authenticated')
  },

  loginLogin: creds => {
    return api.post('auth/login', creds, {
      direct: true,
    })
  },

  loginLogout: () => {
    return api.get('auth/logout', { direct: true })
  },

  loginGenerateResetPasswordToken: id => {
    return api.post(`users/${id}/generate-reset-password-token`)
  },

  loginGetUserInfo: token => {
    return api.get(`auth/get-user-info/${token}`)
  },

  loginResetPassword: (token, newPassword) => {
    return api.post(`auth/reset-password/${token}`, { password: newPassword })
  },
}

export default login
