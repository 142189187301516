<template>
  <MenuList
    :routers="$router.options.routes"
    :filter="filterRoute"
    dense
    class="pa-2"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import MenuList from './MenuList'
import { attributesCheck } from '@/lib/userAttrs'

export default {
  name: 'MainMenu',
  components: { MenuList },
  data: () => ({}),
  computed: {
    ...mapGetters(['userRole', 'userAttributes', 'isAdmin']),
  },
  methods: {
    getTitle(title) {
      if (typeof title === 'function') return title()
      return title
    },
    getIconColor({ is_compare, is_expertise }) {
      if (is_expertise) return 'success darken-2'
      if (is_compare) return 'primary lighten-3'
    },

    isVisibleByRole(role) {
      return !role || role.includes(this.userRole)
    },

    isVisibleByAttributes(attributes) {
      if (this.isAdmin || !attributes) return true
      return attributesCheck(this.userAttributes, attributes)
    },
    isVisibleByRoute(name, meta) {
      const { matched } = this.$route

      return meta?.menuByRoute && !!matched.find(route => route.name === name)
    },
    filterRoute({ name, meta }) {
      return (
        (meta?.menu || this.isVisibleByRoute(name, meta)) &&
        this.isVisibleByRole(meta?.role) &&
        this.isVisibleByAttributes(meta?.attributes)
      )
    },
  },
}
</script>
