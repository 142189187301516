/** Проверка атрибутов пользователя {userAttributes} на соответствие {attributes} */
export const attributesCheck = (userAttributes, attributes) => {
  if (!userAttributes && attributes) return false
  if (!attributes) return true
  if (typeof attributes === 'string') {
    attributes = [attributes]
  }
  if (!Array.isArray(attributes)) return true

  const canDoThis = attribute => {
    const path = attribute.split('.')
    if (!path) return true

    let item = userAttributes || {}
    for (const key of path) {
      item = item?.[key]
    }

    return Boolean(item)
  }

  for (const attr of attributes) {
    if (canDoThis(attr)) return true
  }
  return false
}
