import store from '@/store'
import { standartsModule } from '../store/const'
import { SCN } from '../config/standartsListFields'
import { menuStyleLevel1, ROUTE_STANDARTS } from '@/router/const'

/** Может просматривать фильтры */
export const StdCanRead = 'std.canRead'

const routes = [
  /* Каталог Стандартов */
  {
    path: '/stds',
    name: ROUTE_STANDARTS,
    component: () =>
      import(
        /* webpackChunkName: "standarts" */ '../views/StandartsCatalogView.vue'
      ),
    meta: {
      menu: true,
      exact: false,
      singleMenu: true,
      requireAuth: true,
      title: 'Стандарты медицинской помощи', // Каталог стандартов
      icon: 'mdi-list-box-outline',
      style: menuStyleLevel1,
      attributes: [StdCanRead],
    },
    children: [
      {
        path: '',
        name: SCN.GROUP,
        component: () =>
          import(
            /* webpackChunkName: "standarts" */ '../views/StandartsCatalogGroupPage.vue'
          ),
        meta: {
          requireAuth: true,
          title: 'Группы по профилям стандартов',
          breadSkip: true,
          icon: 'mdi-list-box-outline',
        },
      },
      {
        path: 'gid-:gid',
        name: SCN.CLIN_REC + '_gid',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "standarts" */ '../views/StandartsCatalogView.vue'
          ),
        meta: {
          requireAuth: true,
          title: () =>
            // store.getters.standartsCatalogNames?.[SCN.GROUP] || '...',
            store.getters[`${standartsModule}/standartsCatalogNames`]?.[
              SCN.GROUP
            ] || '...',

          // caption: 'Клинические рекомендации',
        },
        children: [
          {
            path: '',
            name: SCN.CLIN_REC,
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "standarts" */ '../views/StandartsCatalogClinRecPage.vue'
              ),
            meta: {
              requireAuth: true,
              caption: 'Клинические рекомендации',
            },
          },
          {
            path: 'cid-:cid',
            name: SCN.STANDART + '_cid',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "standarts" */ '../views/StandartsCatalogView.vue'
              ),
            meta: {
              requireAuth: true,
              title: () =>
                // store.getters.standartsCatalogNames?.[SCN.CLIN_REC] || '...',
                store.getters[`${standartsModule}/standartsCatalogNames`]?.[
                  SCN.CLIN_REC
                ] || '...',
            },
            children: [
              {
                path: '',
                name: SCN.STANDART,
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "standarts" */ '../views/StandartsCatalogStandartPage.vue'
                  ),
                meta: {
                  requireAuth: true,
                  caption: 'Стандарты медицинской помощи',
                },
              },
              {
                path: 'mid-:mid',
                name: SCN.MODULE + '_mid',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "standarts" */ '../views/StandartsCatalogView.vue'
                  ),
                meta: {
                  requireAuth: true,
                  title: () =>
                    // store.getters.standartsCatalogNames?.[SCN.STANDART] ||
                    store.getters[`${standartsModule}/standartsCatalogNames`]?.[
                      SCN.STANDART
                    ] || '...',
                },
                children: [
                  {
                    path: '',
                    name: SCN.MODULE,
                    props: true,
                    component: () =>
                      import(
                        /* webpackChunkName: "standarts" */ '../views/StandartsCatalogModulePage.vue'
                      ),
                    meta: {
                      requireAuth: true,

                      caption: 'Модуль стандарта',
                    },
                  },
                ],
                redirect: {
                  name: SCN.MODULE,
                },
              },
            ],
            redirect: {
              name: SCN.STANDART,
            },
          },
        ],
        redirect: {
          name: SCN.CLIN_REC,
        },
      },
    ],
    redirect: {
      name: SCN.GROUP,
    },
  },
]

export default routes
