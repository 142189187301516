<template>
  <v-breadcrumbs
    v-if="showOnMain || !isMainRoute"
    v-bind="$attrs"
    :items="breadCrumbs"
  >
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :to="item.to" exact>
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { getTitle } from '@/lib/strUtils'

export default {
  name: 'BreadCrumbs',
  inheritAttrs: false,
  props: {
    titleProp: {
      type: String,
      default: 'title',
    },
    skipProp: {
      type: String,
      default: 'breadSkip',
    },
    showOnMain: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMainRoute() {
      const { matched } = this.$route
      return matched.length === 1 && !matched[0].path
    },
    mainCrumb() {
      const mainRoute = this.$router.options.routes.find(
        ({ path }) => path === '/'
      )
      if (mainRoute) {
        const { name, meta } = mainRoute
        const text = getTitle(meta[this.titleProp]) || 'Главная'
        return {
          to: { name },
          text,
        }
      } else
        return {
          to: { path: '/' },
          text: 'Главная',
        }
    },

    breadCrumbs() {
      // начинаем с главной
      const crumbs = [{ ...this.mainCrumb }]
      if (this.isMainRoute) return crumbs

      // текущие роуты в массиве
      const { matched, params } = this.$route

      matched?.forEach(({ name, meta, props }) => {
        if (!meta[this.skipProp] && getTitle(meta[this.titleProp]))
          crumbs.push({
            to: { name, ...(props?.default ? { params } : {}) },
            text: getTitle(meta[this.titleProp]),
          })
      })
      return crumbs
    },
  },
}
</script>
