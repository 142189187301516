import { ROUTE_USERS, menuStyleLevel1, groupAdmin } from '@/router/const'
import { ADMIN } from '@/store/const/userRoles'

const routes = [
  {
    path: '/users',
    name: ROUTE_USERS,
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/UsersView.vue'),
    meta: {
      menu: true,
      requireAuth: true,
      role: [ADMIN],
      group: groupAdmin,
      title: 'Пользователи',
      icon: 'mdi-account-multiple',
      style: menuStyleLevel1,
    },
  },
]

export default routes
