import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/lib/axiosApi'
import login from '@/store/modules/login'

Vue.use(Vuex)

const strict = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  strict,
  state: {
    server_online: false,
    errors: [],
    drawer: false,
    hasUnsavedChanges: false,
    hasLoading: 0,
    hasLogin: false,
    hasTitleGone: false,
    titleGone: '',
  },
  getters: {
    online: state => state.server_online,
    /** флаги для блокировки перехода по страницам (роутер) */
    hasLoginProceed: state => state.hasLogin,
    /** флаги для блокировки перехода по страницам (роутер) */
    hasUnsavedChanges: (state, getters) =>
      state.hasUnsavedChanges || getters.savingExp,
    /** Идёт глобальная загрузка */
    hasLoading: (state, getters) => !!state.hasLoading || getters.loadingExp,
    /** Признак что заголовок спрятался за экран */
    hasTitleGone: state => state.hasTitleGone,
    getTitle: state => state.titleGone,
    lastError: state =>
      state.errors ? state.errors[state.errors.length - 1] : '',
  },
  mutations: {
    setServerOnline(state, online) {
      state.server_online = !!online
    },
    setHasUnsavedChanges(state, unsaved) {
      state.hasUnsavedChanges = !!unsaved
    },
    setHasLoading(state, st) {
      state.hasLoading += st ? 1 : -1
    },
    setLoginProceed(state, st) {
      state.hasLogin = !!st
    },
    setTitleGone(state, gone) {
      state.hasTitleGone = !!gone
    },
    setTitle(state, title) {
      state.titleGone = title ?? ''
    },

    /**
     * ИНИЦИАЛИЗИРУЕМ ПУЛЛ ОШИБОК
     * @param head - заголовок
     * @param text - сообщение ошибки
     * @param error - сама ошибка (можно err или e)
     */
    SET_ERROR(state, { head, text, error, err, e }) {
      const errObj = error ?? err ?? e ?? {}
      const errHead = `${head ? `[${head}]` : ''}`
      const errText = `${text ? ` ${text}` : ''}`

      const message = `${errHead}${errText} ${
        errObj?.response?.data?.message || errObj
      }`

      errObj.message = message

      // если не был в последним разе
      const itWas = state.errors?.[state.errors.length - 1]?.message === message
      if (!itWas) {
        state.errors.push(errObj)

        Vue.$toast.error(message, {
          showCloseButtonOnHover: true,
          timeout: 5000,
          icon: true,
          onClose: () => {
            store.commit('CLEAR_ERROR', message)
          },
        })
      }
    },
    CLEAR_ERROR(state, message) {
      let index = state.errors.length - 1
      if (message) {
        index = state.errors?.findIndex(obj => obj?.message === message)
      }
      if (index > -1) state.errors.splice(index, 1)
    },
  },
  actions: {
    /**
     * ИНИЦИАЛИЗИРУЕМ СПРАВОЧНИКИ
     * @param dispatch
     * @returns {Promise<void>}
     * @constructor
     */
    async INIT({ commit /*, dispatch */ }) {
      try {
        // грузим всё скопом
        await Promise.all([
          // dispatch('GET_AGE_CATEGORIES'),
        ])
      } catch (error) {
        commit('SET_ERROR', {
          head: 'INIT',
          error,
        })
      }
    },

    async CHECK_API({ commit, dispatch }) {
      try {
        const state = await api.checkServer()
        commit('setServerOnline', state)
        if (state) {
          dispatch('check_auth') // проверка авторизованы мы или нет
        } else {
          setTimeout(() => {
            dispatch('CHECK_API')
          }, 6000)
        }
      } catch {
        commit('setServerOnline', false)
      }
    },

    // Скачивает файл по ссылке с сервера, берет авторизацию нашего юзера
    async DOWNLOAD_FILE({ commit }, { url, fileName }) {
      try {
        await api.getFile(url, fileName)
      } catch (error) {
        commit('SET_ERROR', {
          head: 'DOWNLOAD_FILE',
          error,
        })
        throw error
      }
    },

    async CREATE_ACTION_LOG(_, { code, message, data }) {
      try {
        await api.post(`actions-log`, {
          code,
          message,
          data,
        })
      } catch (error) {
        console.log(`Ошибка CREATE_ACTION_LOG`, code, message, data)
      }
    },
  },
  modules: {
    login,
  },
})

// считаем кол-во регистраций модуля
const modReg = new Map()

/** регистрация модуля */
export const registerModule = (name, module) => {
  // считаем кол-во регистраций модуля
  modReg.set(name, (modReg.get(name) ?? 0) + 1)

  if (!store?.hasModule(name)) {
    strict && console.info(`[vuex] register module "${name}"`)
    store.registerModule(name, module) // регистрация модуля
  }
}

/** Удалить динамически зарегистрированный модуль */
export const unregisterModule = name => {
  // считаем кол-во разрегистраций модуля
  const count = modReg.get(name) ?? 0
  modReg.set(name, count - 1)

  if (store?.hasModule(name) && count <= 1) {
    strict && console.info(`[vuex] unregister module "${name}"`)
    store.unregisterModule(name) // удаляем модуль
  }
}

export const STATUS = {
  IDLE: '',
  CREATING: 'создание',
  UPDATING: 'обновление',
  LOADING: 'загрузка',
  DELETING: 'удаление',
  CHANGE: 'смена статуса',
}

export default store
