export const ROUTE_LOGIN = 'Login'
export const ROUTE_MAIN = 'Main'
export const ROUTE_DEMO = 'DemoFutures'
export const ROUTE_USERS = 'Users'
export const ROUTE_STANDARTS = 'Standarts'
export const ROUTE_REQUIREMENT = 'Requirement'

export const ROUTE_403 = 'AccessDenied'
export const ROUTE_404 = 'PageNotFound'

export const menuStyleLevel1 = {
  activeClass: 'green lighten-3',
  titleClass: 'text-body-1',
}

export const menuStyleLevel2 = {
  activeClass: 'blue lighten-4',
  titleClass: 'text-body-2 ',
}

export const menuStyleLevel3 = {
  activeClass: 'green lighten-5',
  titleClass: 'text-caption ',
}

export const groupAdmin = 'Администрирование'
