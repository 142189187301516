import router from '@/router'
import api from '@/api'
import { ADMIN, USER } from '../const/userRoles'
import ls from '@/lib/localStorageUtils'

import { create } from 'vue-modal-dialogs'
import LoginDlgVue from '@/pages/login/LoginDlg'

const cUserParam = 'user'
const cTokenParam = 'Token'

const state = {
  token: ls.loadDictVersion(cUserParam, '', cTokenParam),
  user: ls.loadDictDataDef(cUserParam, {}, '', cTokenParam),
  authenticating: false,
}

api.setAccessToken(state.token)
api.setApiVersion('v1.0.1')

var loginDlg = create(LoginDlgVue, 'closeble', 'show-cancel')

function setUnauthorizedEvent() {
  api.unauthorizedEvent(async () => {
    console.log('Hi Unauthorized men, go to Login please')

    const result = await loginDlg(true, false)
    if (!result) throw new Error('Финита ля комедия')
  })
}
function clearUnauthorizedEvent() {
  api.unauthorizedEvent(null)
}

const getters = {
  //флаг залогиненного пользователя
  isLoggedIn: state => !!state.token,
  isAuthenticating: state => !!state.authenticating,
  //имя пользователя
  userLogin: state => state.user?.email,
  //имя пользователя
  userName: state => state.user?.fio,
  // ID
  userID: state => state.user?.id,
  //имя роли пользователя - поле name из таблицы expertise_types
  userRole: state => state.user?.role?.name || 'guest',
  //название роли пользователя - поле title из таблицы expertise_types
  userRoleTitle: state => state.user?.role?.title || 'Ананимус',
  // userRoleData: (state, getters) => {
  //   return getters.userRoles.find(obj => obj.id === state.user.role.id) || []
  // },
  //Атрибуты пользователя - поле attributes
  userAttributes: state => state.user?.attributes || {},

  isAdmin: (state, getters) => getters.userRole === ADMIN,
  isUser: (state, getters) => getters.userRole === USER,
}

const mutations = {
  authenticated(state, val) {
    state.authenticating = val
  },
  set_current_role(state, user) {
    if (state.user.id === user.id) {
      state.user.role_id = user.role.id
    }
  },
  auth_success(state, { token, user }) {
    state.token = token
    state.user = user
    api.setAccessToken(token)

    ls.saveDict(cUserParam, token, user, true, '', cTokenParam)
    setUnauthorizedEvent()
  },
  logout(state) {
    state.token = ''
    state.user = {}
    api.setAccessToken()
    ls.clearDict(cUserParam, '', cTokenParam)
    clearUnauthorizedEvent()
  },
}

const actions = {
  async check_auth({ commit, dispatch, getters, state }) {
    if (getters.isAuthenticating || !getters.isLoggedIn) return
    try {
      commit('authenticated', true)
      api.setAccessToken(state.token)
      const ax = await api.loginCheckAuth()

      if (!ax.authenticated && state.token) {
        throw new Error('Ошибка авторизации')
      }
      commit('auth_success', { token: state.token, user: ax.user })
      if (getters.isLoggedIn) dispatch('INIT')
    } catch (e) {
      dispatch('logout')
      //throw new Error('Ошибка авторизации')
    } finally {
      commit('authenticated', false)
    }
  },

  async login({ commit, dispatch }, creds) {
    try {
      const { access_token, user } = await api.loginLogin(creds)
      commit('auth_success', { token: access_token, user })

      dispatch('INIT')
    } catch (err) {
      commit('SET_ERROR', { head: 'Ошибка авторизации', err })
      throw new Error('Ошибка авторизации')
    }
  },
  async logout({ commit, getters }, redirectNeeded = true) {
    if (!getters.isLoggedIn) return
    // не ждём потверждения
    api.loginLogout().catch(() => {})

    commit('logout')
    if (redirectNeeded) {
      const redirect = router.currentRoute.fullPath ?? undefined
      await router.push({ name: 'Login', query: { redirect } }).catch(() => {})
    }
  },
  async generateResetPasswordToken({ commit }, userId) {
    try {
      // возвращаем теперь весь объект user, у него там и ФИО и токен сброса
      return await api.loginGenerateResetPasswordToken(userId)
    } catch (err) {
      commit('SET_ERROR', {
        head: 'Ошибка генерации токена сброса пароля',
        err,
      })
      throw new Error('Ошибка генерации токена сброса пароля')
    }
  },
  async getUserInfo({ commit }, token) {
    try {
      let response = await api.loginGetUserInfo(token)
      return response.email
    } catch (err) {
      commit('SET_ERROR', { head: 'Ошибка проверки токена', err })
      throw new Error('Ошибка проверки токена')
    }
  },
  async resetPassword({ commit }, { token, newPassword }) {
    try {
      return await api.loginResetPassword(token, newPassword)
    } catch (err) {
      commit('SET_ERROR', { head: 'Ошибка создания нового пароля', err })
      throw new Error('Ошибка создания нового пароля')
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
