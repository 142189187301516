import store from '@/store'
import { dictMKB10Module } from '../store/mkb10/const'

import { menuStyleLevel1, menuStyleLevel2 } from '@/router/const'

/** Может просматривать справочник МКБ-10  */
export const CanReadMKB10 = 'dict.canReadMKB10'
export const FiltersCanRead = 'filters.canRead'

export const ROUTE_REFS = 'References'
export const ROUTE_REF_MKB10 = 'MKB10'

const routes = [
  /* Справочники */
  {
    path: '/references',
    name: ROUTE_REFS,
    component: () =>
      import(/* webpackChunkName: "refs" */ '../views/ReferencesView.vue'),
    meta: {
      menu: true,
      requireAuth: true,
      title: 'Справочники',
      icon: 'mdi-book-multiple-outline',
      style: menuStyleLevel1,
    },
    children: [
      {
        path: 'mkb10',
        // path: 'mkb10/:test', // пример
        name: ROUTE_REF_MKB10,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "refsMkb10" */ '../views/ReferencesMKB10.vue'
          ),
        meta: {
          requireAuth: true,
          menu: true,
          title: 'МКБ-10',
          attributes: [CanReadMKB10, FiltersCanRead],
          captionHtml: () =>
            `Справочник НСИ: МКБ-10${
              store.getters[`${dictMKB10Module}/nsiVersion`]
                ? `  <span class="text--disabled text-subtitle-2">(v${
                    store.getters[`${dictMKB10Module}/nsiVersion`]
                  })</span>`
                : ''
            }`,
          icon: 'mdi-book-education-outline',
          // params: () => ({ test: 'ok' }), // пример
          style: menuStyleLevel2,
        },
      },
    ],
    redirect: {
      name: ROUTE_REF_MKB10,
      // params: { test: 'good' }, // пример
    },
  },
]

export default routes
